<template>
  <common-trs-card class="trs-table-container-card">
    <common-trs-data-table
      :headers="headers"
      :items="details"
      :options.sync="tableOptions"
      :server-items-length="totalCount"
      :loading="loading"
    >
      <template v-slot:[`item.username`]="{ item }">
        <common-trs-a-link
          class="overflow-ellipsis cursor-pointer font-weight-medium"
          @click="goToDetails(item)"
        >
          {{ item.username }}
        </common-trs-a-link>
      </template>
      <template v-slot:[`item.roles`]="{ item }">
        <span v-if="item.roles">{{ item.roles.map(r => r.role_display_name).join(', ') }}</span>
      </template>
      <template v-slot:no-data>
        <span> No Results Found. </span>
      </template>
    </common-trs-data-table>
  </common-trs-card>
</template>

<script>
  export default {
    name: 'UserList',

    props: {
      loading: {
        type: Boolean,
        required: true,
        default: false,
      },
      options: {
        type: Object,
        required: true,
        default: () => {},
      },
      details: {
        type: Array,
        required: true,
        default: () => [],
      },
      totalCount: {
        type: Number,
        required: true,
        default: 0,
      },
    },

    data: () => ({
      headers: [
        { text: 'Username', value: 'username', sortable: true },
        { text: 'First Name', value: 'first_name', sortable: true },
        { text: 'Last Name', value: 'last_name', sortable: true },
        { text: 'Roles', value: 'roles', sortable: false },
      ],
    }),

    computed: {
      tableOptions: {
        get () {
          return this.options
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    methods: {
      goToDetails (item) {
        this.$store.dispatch('access/setSelectedUser', item)
        this.$router.push({ name: 'UserProfileDetails' })
      },
    },
  }
</script>
