<template>
  <common-trs-page-container
    :padding="0"
  >
    <template v-slot:page-content>
      <common-app-page-header-v2
        icon="icon-access-control"
        title="Access Control"
      >
        <template v-slot:primary-filter="{ }">
          <common-input-text-field
            v-model="filters.username"
            label="Username"
            color="primary"
            clearable
            hide-details
            prepend-inner-icon="mdi-magnify"
            dense
            single-line
          />
        </template>
        <template v-slot:actions>
          <div class="d-flex justify-space-between">
            <div />
            <div class="d-flex align-center">
              <common-trs-btn
                v-feature="CONSTS.CAPTABLE_FEATURES.ADD_CAPTABLE_USER"
                text
                large
                type="primary"
                class="mr-auto white--text"
                :to="{ name: 'SettingsAccess.CreateUser' }"
              >
                <v-icon class="mr-2">
                  mdi-plus-circle
                </v-icon>
                New User
              </common-trs-btn>
            </div>
          </div>
        </template>
      </common-app-page-header-v2>

      <user-list
        :loading="loading"
        :options="options"
        :details="details"
        :total-count="totalCount"
        @input="updateOptions"
      />
    </template>
  </common-trs-page-container>
</template>

<script>
  import UserList from './components/List'

  import * as captableAdminService from '@/services/captable-admin-service'
  import debounce from 'lodash/debounce'

  export default {
    name: 'SettingsIndex',

    components: {
      UserList,
    },

    data: () => ({
      filters: {},
      loading: false,
      options: {},
      totalCount: 0,
      details: [],
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      filters: {
        handler: debounce(function (val) {
          this.options.page = 1
          this.loadData()
        }, 500),
        deep: true,
      },
      options: {
        handler () {
          this.loadData()
        },
        deep: true,
      },
    },

    mounted () {
      this.loadData()
    },

    methods: {
      async loadData () {
        this.loading = true
        try {
          const resp = await captableAdminService.getAllUserList(this.captableId, {
            ...this.options,
            filters: this.filters,
          })
          this.totalCount = resp.count
          this.details = resp.results
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
        this.loading = false
      },
      updateOptions (tableOptions) {
        this.options = tableOptions
      },
    },
  }
</script>
